<template>
  <!-- 任务评价 -->
  <div class="pjLi">
    <div v-if="gzpj" class="pjOkBox">
      <div class="pjBox">
        <div class="usPtx">
          <img class="usphoto" :src="props.iobj.sqPhoto" />
          <strong>{{ props.iobj.sqName }}</strong>
        </div>
        <div class="pjNrq">
          <div class="">
            <el-rate v-model="pj.pjFen" />
          </div>
          <div class="Mt12">
            {{ pj.pjTex }}
          </div>
          <div class="" v-if="pj.pjFile.length > 0">
            <div class="imgFiles">
              <img
                v-for="itx in pj.pjFile"
                :key="itx"
                style="max-width: 160px; max-height: 160px;margin-top:10px;margin-right:10px;"
                :src="itx"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="pjNoBox">
      <div class="pjBox">
        <div class="usPtx">
          <img class="usphoto" :src="props.iobj.sqPhoto" />
          <strong>{{ props.iobj.sqName }}</strong>
        </div>
        <div class="pjNrq">
          <div class="">
            <el-rate v-model="xing" />
          </div>
          <div class="Mt12">
            <div v-if="userIdImgA.length < 5">
              <el-upload
                class="upload-demo"
                method="post"
                action=""
                :before-upload="uploadsA"
                multiple
                :limit="3"
                :file-list="fileList"
                style="display:flex;"
              >
                <el-button size="mini">上传文件</el-button>
                <template #tip>
                  <div class="el-upload__tip" style="margin-left:12px;">
                    jpg/png 格式，文件大小不超过 500KB. 最多4张图片
                  </div>
                </template>
              </el-upload>
            </div>
            <div class="imgFiles">
              <img
                v-for="itu in userIdImgA"
                :key="itu"
                style="max-width: 160px; max-height: 160px;margin-top:10px;margin-right:10px;"
                :src="itu"
              />
            </div>
          </div>
          <div class="Mt12">
            <el-input
              v-model="pjtex"
              :rows="2"
              type="textarea"
              placeholder="输入评价内容"
            />
          </div>
          <div class="Mt12">
            <el-button type="primary" size="small" @click="pingjiaBtn"
              >确定评价</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, watch } from 'vue'
import { uploadImg } from '@/api/user'
import { getAddTaskEvaluation } from '@/api/task'
import { ElMessage, ElMessageBox } from 'element-plus'
import tools from '@/utils/tools'
import { sign } from '@/utils/storage'
const gzpj = ref(true)
const pj = ref({
  pjFen: null,
  pjTex: '',
  pjFile: []
})
const props = defineProps({
  iobj: {
    type: Object,
    required: { title: 'aaa' }
  }
})
const xing = ref(null)
const pjtex = ref('')
const files = ref('')
const fileList = ref([])
// 上传文件
const userIdImgA = ref([])
const uploadsA = async (file, uploadFiles) => {
  var formData = new FormData()
  formData.append('file', file)
  formData.append('tg', 'ok')
  var revv = await uploadImg(formData)
  if (files.value === '') {
    files.value = revv
  } else {
    files.value = files.value + ',' + revv
  }
  userIdImgA.value.push(window.g.baseURL + revv)
}

const pingjiaBtn = () => {
  if (pjtex.value === '') {
    ElMessage.error('请输入评价内容')
    return false
  } else if (xing.value === null || xing.value === 0) {
    ElMessageBox.confirm('确定要给 0 分么?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        tijiao()
      })
      .catch(() => {
        return false
      })
  } else {
    tijiao()
  }
}
const tijiao = async () => {
  var parmas = {
    tsID: props.iobj.tsid,
    pjFen: xing.value,
    pjTex: pjtex.value,
    pjFile: files.value
  }
  console.log(parmas)
  tools.basic.filterEmptyData(parmas)
  var res = await getAddTaskEvaluation(sign(parmas))
  if (res === 'ok') {
    gzpj.value = true
    pj.value = {
      pjFen: xing.value,
      pjTex: pjtex.value,
      pjFile: zfczsc(files.value)
    }
    if (files.value === '') {
      pj.value.pjFile = []
    } else {
      pj.value.pjFile = zfczsc(files.value)
    }
  }
}
const zfczsc = v => {
  var sz = v.split(',')
  var irr = []
  sz.forEach(elm => {
    irr.push(window.g.baseURL + elm)
  })
  return irr
}
watch(
  () => props.iobj,
  (newVal, oldVal) => {
    console.log('-pingjia--newVal, oldVal', newVal, oldVal)
    newVal.sqPhoto = window.g.baseURL + newVal.sqPhoto
    if (newVal.pjFen === null) {
      gzpj.value = false
    } else {
      pj.value = {
        pjFen: newVal.pjFen,
        pjTex: newVal.pjTex,
        pjFile:
          newVal.pjFile === null
            ? ''
            : zfczsc(JSON.parse(JSON.stringify(newVal.pjFile)))
      }
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
.pjLi {
  border-bottom: dotted 1px #ddd;
  padding: 20px 0;
}
.pjBox {
  display: flex;
  line-height: 24px;
  .usPtx {
    flex: 1;
    .usphoto {
      width: 24px;
      height: 24px;
      display: inline-block;
      margin-right: 10px;
      border-radius: 50%;
      overflow: hidden;
      float: left;
    }
  }
  .pjNrq {
    flex: 3;
  }
  .pjLevel {
    flex: 1;
  }
  .pjText {
    flex: 2;
  }
}
</style>
