<template>
  <!-- 撤销审核 -->
  <div class="myTaskShowBox">
    <div class="taskTesBox">
      <div class="tBox">
        <!-- <div class="Ptit"><strong>申请撤销 </strong></div> -->
        <div v-if="jxList.length === 0" class="taskTesBox">
          <el-empty description="暂无内容" />
        </div>
        <div v-else class="">
          <to-apply-for-li
            v-for="(cam, cax) in jxList"
            :key="cax"
            :iobj="cam"
            rtes="阶段："
          ></to-apply-for-li>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps } from 'vue'
import { getGzToapplyforList } from '@/api/task'
// import { uploadData } from '@/api/user'
import { sign } from '@/utils/storage'
import ToApplyForLi from '@/components/Task/ToApplyForLi.vue'
// import { ElMessage } from 'element-plus'

const props = defineProps({
  zrwTask: {
    type: Array,
    default: () => []
  },
  taskObj: {
    type: Object,
    default: () => ({})
  }
})

// 列表
const parmas = ref({
  tID: props.taskID,
  type: 1,
  tg: 'ok',
  s: 'i' // i查自己,all查所有人
})
const jxList = ref([])
const getData = async () => {
  parmas.value.sjs = Math.ceil(Math.random() * 10)
  var res = await getGzToapplyforList(sign(parmas.value))
  res.forEach(elt => {
    elt.gz = true
  })
  jxList.value = res
  delete parmas.value.sign
}
// getData()
watch(
  () => props.zrwTask,
  (newVal, oldVal) => {
    console.log('--222--- results ----watch-----', newVal, oldVal)
    // zrwTex.value = newVal[0].taskContent
    // parmas.value.tuID = newVal[0].performUserID // 执行人
    parmas.value.tID = newVal[0].taskID // 主任务
    getData()
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
.upload-demo > .el-upload {
  text-align: left !important;
}
</style>
