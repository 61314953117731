<template>
  <!-- 雇主 - 任务评价 -->
  <div class="myTaskShowBox">
    <div class="taskTesBox">
      <div class="tBox">
        <div class="Ptit">
          <strong>任务评价</strong>
        </div>

        <div v-if="jxList.length === 0" class="taskTesBox">
          <el-empty description="暂无内容" />
        </div>
        <div v-else class="">
          <evaluation
            v-for="(cam, cax) in jxList"
            :key="cax"
            :iobj="cam"
          ></evaluation>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps } from 'vue'
import { getTaskEvaluationList } from '@/api/task'
// import { uploadData } from '@/api/user'
import { sign } from '@/utils/storage'
import Evaluation from '@/components/Task/Evaluation.vue'
// import { ElMessage } from 'element-plus'

const props = defineProps({
  taskObj: {
    type: Object,
    default: () => {}
  },
  zrwTask: {
    type: Array,
    default: () => []
  }
})

// 列表
const parmas = ref({
  tID: props.taskObj.ID,
  tg: 'ok',
  s: 'i' // i查自己,all查所有人
})
const jxList = ref([])
const getData = async () => {
  parmas.value.sjs = Math.ceil(Math.random() * 10)
  console.log('请求助时--', parmas.value)
  var res = await getTaskEvaluationList(sign(parmas.value))

  res.forEach(itm => {
    itm.gz = true
  })

  jxList.value = res
  delete parmas.value.sign
}
watch(
  () => props.taskObj,
  (newVal, oldVal) => {
    parmas.value.tID = newVal.ID

    // 逻辑代码
    console.log('--gz--evaluation---watch---newVal, oldVal', newVal, oldVal)
    console.log('--gz--evaluation---parmas', parmas.value)
    getData()
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped>
.upload-demo > .el-upload {
  text-align: left !important;
}
</style>
