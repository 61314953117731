<template>
  <!-- 我的任务 - 雇主- 详情 -->

  <div>
    <div class="Ptit">
      <strong class="tit">雇主-任务详情</strong>
      <span class="rset">
        <template v-if="gtdt">
          <el-button @click="goTask" size="small" type="primary"
            >去选择后补人员</el-button
          >
        </template>
      </span>
    </div>
    <div class="tastTiele">{{ taskObj.taskName }}</div>

    <div class="Mt12">
      <div class="nTabs gz t82">
        <span @click="nTabsFun('1')" :class="nTabs === '1' ? 'act' : ''">
          任务介绍
        </span>
        <span @click="nTabsFun('2')" :class="nTabs === '2' ? 'act' : ''">
          绩效监控
        </span>
        <span @click="nTabsFun('3')" :class="nTabs === '3' ? 'act' : ''">
          阶段成果
        </span>
        <span @click="nTabsFun('5')" :class="nTabs === '5' ? 'act' : ''">
          撤销审核
        </span>
        <span @click="nTabsFun('6')" :class="nTabs === '6' ? 'act' : ''">
          验收审核
        </span>
        <span @click="nTabsFun('9')" :class="nTabs === '9' ? 'act' : ''">
          任务评价
        </span>
      </div>
      <div class="Mt12">
        <about v-if="nTabs === '1'" :taskObj="taskObj"></about>
        <template v-if="nTabs === '2'">
          <span v-if="taskObj.state === 2">
            <el-result
              icon="warning"
              title="任务未开始"
              sub-title="请先选择人员并开始任务"
            >
              <template #extra>
                <el-button @click="goTask">去任务页</el-button>
              </template>
            </el-result>
          </span>
          <!-- 绩效 -->
          <performanc v-else :zrwTask="zrwTask" :taskObj="taskObj">
          </performanc>
        </template>
        <template v-if="nTabs === '3'">
          <span v-if="taskObj.state === 2">
            <el-result
              icon="warning"
              title="任务未开始"
              sub-title="请先选择人员并开始任务"
              ><template #extra>
                <el-button @click="goTask">去任务页</el-button>
              </template>
            </el-result>
          </span>
          <results v-else :zrwTask="zrwTask" :taskObj="taskObj"></results>
        </template>
        <template v-if="nTabs === '5'">
          <span v-if="taskObj.state === 2">
            <el-result
              icon="warning"
              title="任务未开始"
              sub-title="请先选择人员并开始任务"
              ><template #extra>
                <el-button @click="goTask">去任务页</el-button>
              </template>
            </el-result>
          </span>
          <toapplyfor v-else :zrwTask="zrwTask" :taskObj="taskObj"></toapplyfor>
        </template>
        <template v-if="nTabs === '6'">
          <span v-if="taskObj.state === 2">
            <el-result
              icon="warning"
              title="任务未开始"
              sub-title="请先选择人员并开始任务"
              ><template #extra>
                <el-button @click="goTask">去任务页</el-button>
              </template>
            </el-result>
          </span>
          <acceptance v-else :zrwTask="zrwTask" :taskObj="taskObj"></acceptance>
        </template>
        <template v-if="nTabs === '9'">
          <span v-if="taskObj.state === 2">
            <el-result
              icon="warning"
              title="任务未开始"
              sub-title="请先选择人员并开始任务"
              ><template #extra>
                <el-button @click="goTask">去任务页</el-button>
              </template>
            </el-result>
          </span>
          <evaluation v-else :zrwTask="zrwTask" :taskObj="taskObj"></evaluation>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { getGzTaskShow, getHbTaskWQR } from '@/api/task'
import { sign } from '@/utils/storage'
import { useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import About from './taskGz/about'
import Performanc from './taskGz/performanc' // 绩效
import Results from './taskGz/results' // 阶段成果
import Toapplyfor from './taskGz/toapplyfor' // 撤销审核
import Acceptance from './taskGz/acceptance' // 验收
import Evaluation from './taskGz/evaluation' // 任务评价
const router = useRouter()
const taskId = router.currentRoute.value.query.d
const tsID = ref(0) // 子任务ID
if (taskId === 'undefined' || taskId === null || taskId === '') {
  ElMessageBox.alert('此任务不存在~', '出错了', {
    confirmButtonText: 'OK',
    callback: action => {
      router.push('/usindex')
    }
  })
}

// 切换
const nTabs = ref('1')
const nTabsFun = vv => {
  nTabs.value = vv
}

// 详情
const taskObj = ref({})
const zrwTask = ref([])
const getData = async () => {
  var rw = await getGzTaskShow(sign({ id: taskId }))

  taskObj.value = rw.task
  zrwTask.value = rw.ztask
  tsID.value = rw.task.tsid
  // 如果有被撤销的任务
  if (rw.repair !== null) {
    getZrwDataNum()
  }
  // console.log('子任务ID==', tsID.value)
}
getData()

// 查询子任务 后补任务  是否有未确认的
const gtdt = ref(false)
const getZrwDataNum = async () => {
  var zrwvv = await getHbTaskWQR(sign({ id: taskId }))
  if (zrwvv !== 0) {
    gtdt.value = true
  }
}

const goTask = () => {
  router.push(`/task?d=${taskId}`)
}
</script>

<style lang="scss" scoped>
.taskShowBox .taskTesBox {
  flex: 2;
}
</style>
