<template>
  <!-- 绩效 - 雇主 -->
  <div class="">
    <div class="UsJXLis TxC">
      <span
        v-for="(itm, itx) in zrwTask"
        :key="itx"
        :class="act === itx ? 'act' : ''"
        @click="jixiaoLiClick(itm, itx)"
      >
        {{ itm.name }}
      </span>
    </div>
    <div class="zrwTex">{{ zrwTex }}</div>
    <div class="">
      <div class="tBox">
        <div class="Ptit"><strong>绩效列表 </strong></div>
        <div class="">
          <task-li-tes
            v-for="(cam, cax) in jxList"
            :key="cax"
            :iobj="cam"
          ></task-li-tes>
        </div>
        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="parmas.total"
            @current-change="paginationPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps } from 'vue'
import { getGzPerformancList } from '@/api/task'
import { sign } from '@/utils/storage'
import TaskLiTes from '@/components/Public/TaskLiTes.vue'
// import { ElMessage } from 'element-plus'

const props = defineProps({
  zrwTask: {
    type: Array,
    default: () => []
  }
})
const act = ref(0)
const zrwTex = ref('') // 子任务内容

const jixiaoLiClick = (itm, itx) => {
  zrwTex.value = itm.taskContent
  act.value = itx
  parmas.value.page = 1
  parmas.value.tuID = itm.performUserID // 执行人
  // parmas.value.tsID = itm.tsid // 子任务
  parmas.value.tID = itm.taskID // 主任务
  delete parmas.value.sign
  getData()
}

const parmas = ref({
  tuID: 0, // 执行人ID
  page: 1,
  size: 10,
  total: 0,
  tg: 'ok',
  // tsID: '', // 子任务ID
  tID: props.taskID, // 主任务ID
  s: 'i' // i查自己,all查所有人
})
const jxList = ref([])
const getData = async () => {
  parmas.value.sjs = Math.ceil(Math.random() * 10)

  var res = await getGzPerformancList(sign(parmas.value))
  res.list.forEach(elt => {
    if (elt.type === 1) {
      elt.rtes = '日报：'
    } else if (elt.type === 2) {
      elt.rtes = '周报：'
    } else {
      elt.rtes = '月报：'
    }
  })
  jxList.value = res.list
  parmas.value.total = res.total
  delete parmas.value.sign
}

const paginationPage = v => {
  parmas.value.page = v
  getData()
}

watch(
  () => props.zrwTask,
  (newVal, oldVal) => {
    console.log('-----gz---performanc-----watch---newVal---', newVal)
    if (newVal.length > 0) {
      zrwTex.value = newVal[0].taskContent
      parmas.value.tuID = newVal[0].performUserID // 执行人
      parmas.value.tID = newVal[0].taskID // 主任务
    }
    getData()
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss" scoped></style>
